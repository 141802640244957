import React, { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import style from "./style.module.scss"
import { useTranslation } from "react-i18next"
import MainPageWrapper from "../../../components/Main-page-wrapper"
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon"
import { NavLink, useLocation, useRouteMatch } from "react-router-dom"
import { Button } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import logo from "../Header/textLabLogo.png"
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"
import RadialBar from "../UI/RadialBar"
import Tonality from "../Home/Tonality"
import CompareVocabularyComponent from "../UI/tags-cloud/CompareVocabularyComponent"
import CompareSingleDistribution from "../Home/CompareSingleDistribution"
import CLIXComponentCompare from '../CLIX/compare'
import DashHeader from "../Header"
import SentenceConstCLIX from "../CLIX/SentenceConst"
import config from "../../../config"
import { Redirect } from "react-router"
import { PreLoaderCont } from "../HIX//PreLoaderCont"
import {
    getScale, getValue,
    isObjectEmpty,
    chooseColor,
    roundWithPrecision,
    checkClixNotZero,
    countTermsWithPositions,
    convertLanguagesToShort
} from "../../../utils"
import VColoredLinearChart from "../UI/VColoredLinearChart"

export default function Combine() {
    let date = new Date()
    const userName = useSelector(state => state.userData.user.user_name)
    const pdfRef = useRef()
    const pdfRef1 = useRef()
    const pdfRef2 = useRef()
    const pdfRef3 = useRef()
    const pdfRef4 = useRef()

    const { t, i18n: { language: i18nLanguage } } = useTranslation()
    const { theme: { bgColors } } = useTheme()
    const benchmarkName = useRef()

    // Redux
    const resultData = useSelector(state => state.analyseData.result.resultData)
    const dataList1 = useSelector(state => state.dashboardData.resultList[0]?.data)
    const dataList2 = useSelector(state => state.dashboardData.resultList[1]?.data)
    const benchmarks = useSelector(state => state.userData.benchmarks)
    const benchmarkId1 = useSelector(state => state.dashboardData?.resultList[0]?.results?.benchmarkId)
    const benchmarkId2 = useSelector(state => state.dashboardData?.resultList[1]?.results?.benchmarkId)
    const resultList1 = useSelector(state => state.dashboardData.resultList[0]?.results || [])
    const resultList2 = useSelector(state => state.dashboardData.resultList[1]?.results || [])
    const idList0 = useSelector(state => state.dashboardData.idList[0] || [])
    const idList1 = useSelector(state => state.dashboardData.idList[1] || [])
    const text1 = t('number of results {{num}}', { num: idList0?.length })
    const text2 = t('number of results {{num}}', { num: idList1?.length })
    const categoryNames1 = useSelector(state => state.dashboardData.resultList[0]?.results?.categoryNames || [])
    const categoryNames2 = useSelector(state => state.dashboardData.resultList[1]?.results?.categoryNames || [])
    const archiveResult = useSelector(state => state.dashboardData.archiveResult)
    const textLanguage = useSelector(state => state.analyseData.textLanguage)

    // benchmark:
    let benchmark1
    dataList1?.length && dataList1.map(el => {
        benchmark1 = el.benchmark_id
    })
    !!benchmarks && Object.values(benchmarks).map(el => {
        if (el.id === benchmark1) {
            // setBenchmarkName(el.name[i18nLanguage])
            benchmarkName.current = el?.name[i18nLanguage]
        }
    })

    let currentBenchmarkSettings1
    let currentBenchmarkSettings2
    // clixDisplayList
    !!benchmarkId1 && Object.keys(benchmarkId1).map(el => {
        currentBenchmarkSettings1 = (benchmarks[el])
    })
    !!benchmarkId2 && Object.keys(benchmarkId2).map(el => {
        currentBenchmarkSettings2 = (benchmarks[el])
    })
    const targetHix1 = currentBenchmarkSettings1?.settings?.formulaHix?.values?.target || 0
    const targetHix2 = currentBenchmarkSettings2?.settings?.formulaHix?.values?.target || 0
    const targetCLIX1 = currentBenchmarkSettings1?.settings?.formulaCLIX?.values?.target || 0
    const targetCLIX2 = currentBenchmarkSettings2?.settings?.formulaCLIX?.values?.target || 0
    const targetFlesch = currentBenchmarkSettings1?.settings?.formulaFlesch?.values?.target || 0

    const tonalityRows = Object.entries(config.tonalityParameters).reduce((acc, [key, val]) => {
        if (!isObjectEmpty(archiveResult[key])) {
            const arraySize = roundWithPrecision((val.scaleTo[0] - val.scaleFrom[0]) / val.scaleStep) + 1
            const blocks = [...Array(arraySize).keys()].map(x => val.scaleFrom[0] + x * val.scaleStep)
            const exactlyActual = archiveResult[key]
            const actualInRange = blocks.findIndex(val => exactlyActual <= val)

            return [
                ...acc,
                {
                    blocks,
                    leftText: t(val.scaleFrom[1]),
                    rightText: t(val.scaleTo[1]),
                    rangePosition: blocks[actualInRange],
                }
            ]
        }
        return acc
    }, [])


    const downloadPDf = () => {
        const input1 = pdfRef1.current
        const input2 = pdfRef2.current
        const input3 = pdfRef3.current
        const input4 = pdfRef4.current

        let doc = new jsPDF()

        html2canvas(input1).then((canvas) => {
            let imgData = canvas.toDataURL('image/png')
            let imgWidth = 210
            // let pageHeight = canvas.height * 0.30;
            let imgHeight = canvas.height * imgWidth / canvas.width
            let position = 10 // give some top padding to first page
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
            // Second page:
            html2canvas(input2).then((canvas) => {
                let imgData = canvas.toDataURL('image/png')
                let imgWidth = 210
                // let pageHeight = canvas.height * 0.30;
                let imgHeight = canvas.height * imgWidth / canvas.width
                let position = 10 // give some top padding to first page
                doc.addPage('l', 'mm', 'a4')
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                // Third page:
                html2canvas(input3).then((canvas) => {
                    let imgData = canvas.toDataURL('image/png')
                    let imgWidth = 210
                    // let pageHeight = canvas.height * 0.30;
                    let imgHeight = canvas.height * imgWidth / canvas.width
                    let position = 10 // give some top padding to first page
                    doc.addPage('l', 'mm', 'a4')
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)

                    // Fourth page:
                    html2canvas(input4).then((canvas) => {
                        let imgData = canvas.toDataURL('image/png')
                        let imgWidth = 210
                        // let pageHeight = canvas.height * 0.30;
                        let imgHeight = canvas.height * imgWidth / canvas.width
                        let position = 10 // give some top padding to first page
                        doc.addPage('l', 'mm', 'a4')
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)

                        doc.save('file.pdf')
                    })
                })
            })
        })
    }

    const setResult = (resultList) => {
        const ret = []
        // get the benchId
        const benchSortedPair = Object.entries(resultList["benchmarkId"]).sort((a, b) => b[1] - a[1])
        let benchmarkObj

        for (let bh of benchSortedPair) {
            if (!isObjectEmpty(benchmarks[parseInt(bh[0])])) {
                benchmarkObj = benchmarks[parseInt(bh[0])]
            }
        }
        for (const val of config.compareShowValues) {
            if (!isObjectEmpty(resultList[`${val.prefix}.${val.name}`])) {
                // value exist
                const counterBase = config.fullCountBase[val.name]
                const countNumber = resultList[`${val.type}sum.${val.name}`]
                const localTarget = benchmarkObj?.settings[val.name]?.values?.target || -100
                const localTargetYellowGreen = benchmarkObj?.settings[val.name]?.values?.yellowGreen || -100
                const localTargetYellowRed = benchmarkObj?.settings[val.name]?.values?.yellowRed || -100
                const localPercent = countNumber / (resultList[`sum.${counterBase}`] / 100)
                const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed)
                // push to ret
                ret.push({
                    value: roundWithPrecision(localPercent, 0),
                    color: config.compareThemeColors[localColor],
                    text: [resultList[`${val.prefix}text.${val.name}`]],
                    title: t(val.title),
                    subTitle: t(val.subTitle),
                    lCount: countNumber,
                    lCountMax: resultList[`sum.${counterBase}`] || 0,
                    lText: [t(val.lText, { number: localTarget })],
                    lTitle: t(val.lTitle),
                    label: "",
                    withLinear: false,
                    textsComp: false
                })
            }
        }
        return ret
    }
    // ********************************************************************
    // ********************************************************************
    // ****************************CLIX Details **************************
    // ********************************************************************
    // ********************************************************************

    /*** array2maxlist.moreWordsInLetters ***/
    let clixTableCells1 = []
    if (!isObjectEmpty(resultList1[`array2maxlist.moreWordsInLetters`])) {
        clixTableCells1 = Object.entries(resultList1[`array2maxlist.moreWordsInLetters`]).map(([word, freq]) => Object({ word, freq, length: word.length }))
            .sort((a, b) => b.word.length - a.word.length)
    }
    let clixTableCells2 = []
    if (!isObjectEmpty(resultList2[`array2maxlist.moreWordsInLetters`])) {
        clixTableCells2 = Object.entries(resultList2[`array2maxlist.moreWordsInLetters`]).map(([word, freq]) => Object({ word, freq, length: word.length }))
            .sort((a, b) => b.word.length - a.word.length)
    }

    let clixList1
    let clixList2
    let heading1 = []
    let heading2 = []
    let corporateList = []
    let termList = []

    const clixDisplay = (currentBenchmarkSettings, resultList, categoryNames, heading) => {
        let clixListIs = Object.keys(currentBenchmarkSettings?.settings).reduce((acc, val, index) => {
            // if clix parameter is more than 0
            if (currentBenchmarkSettings?.settings[val].values?.clix !== 0 && !!currentBenchmarkSettings?.settings[val].values?.clix) {
                // define needed values
                let scalePercent = 0
                let targetPercent = 0
                let localPercent = 0
                let name = ''
                let color = 'green'
                let localTarget = 0
                let countNumber = 0
                let section = ""
                const countWords = !!resultList["sum.countWords"] ? resultList["sum.countWords"] : 0
                if (!val.startsWith("list")) {
                    // not a number - value from resultList
                    name = t(val)
                    const currentSettings = currentBenchmarkSettings?.settings[val]?.values
                    countNumber = !!resultList[`sum.${val}`] ? resultList[`sum.${val}`] : !!resultList[`arraysizesum.${val}`] ? resultList[`arraysizesum.${val}`] : 0
                    localTarget = getValue(currentSettings, "target", 50)
                    const localScaleFrom = getValue(currentSettings, "scaleFrom", 0)
                    const localScaleTo = getValue(currentSettings, "scaleTo", 100)
                    const localTargetYellowGreen = getValue(currentSettings, "yellowGreen", 50)
                    const localTargetYellowRed = getValue(currentSettings, "yellowRed", 50)
                    if (config.fullCountBase[val] === "") {
                        localPercent = countNumber / (countWords / 100)
                    } else {
                        // if fullCountBase exist use value from it, or by default use countWords
                        const fullValueCount = config.fullCountBase[val] !== undefined ? config.fullCountBase[val] : "countWords"
                        localPercent = countNumber / ((!!resultList[`sum.${fullValueCount}`] ? resultList[`sum.${fullValueCount}`] : countWords) / 100)
                    }
                    // convert real % into  scale size percent
                    const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo)
                    targetPercent = targetPercentTmp
                    scalePercent = scalePercentTmp
                    color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed)
                    section = ""
                } else if (val === 'listTerms') {
                    // if it's a number - it's  Terms
                    const termsSettings = currentBenchmarkSettings?.settings['listTerms']?.values['_list'] || {}
                    const { valuesClix, dataCorporateLanguageClix, dataTermsClix: ids } = checkClixNotZero(currentBenchmarkSettings?.settings)
                    termList = ids?.length && ids.reduce((acc, currentValue, index) => {
                        // if it's a number - it's  Terms
                        countNumber = resultList[`categorysizesum.dataTerms.${currentValue}`]
                        const localSettings = termsSettings[currentValue]
                        localTarget = getValue(localSettings, "target", 50)
                        const localScaleFrom = getValue(localSettings, "scaleFrom", 0)
                        const localScaleTo = getValue(localSettings, "scaleTo", 100)
                        const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50)
                        const localTargetYellowRed = getValue(localSettings, "yellowRed", 50)
                        localPercent = countNumber / (countWords / 100)
                        categoryNames.map(el => {
                            if (String(el.id) === String(currentValue)) {
                                name = el.name[convertLanguagesToShort(i18nLanguage)]
                                // Add avaible name in the config to display later:
                                config.clixDisplay.map(element => {
                                    if (element.title === "Terminology") {
                                        element.subCat.push(name)
                                    }
                                })
                            }
                        })
                        // convert real % into  scale size percent
                        const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo)
                        targetPercent = targetPercentTmp
                        scalePercent = scalePercentTmp
                        color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed)
                        section = "Terminology"

                        return [...acc, {
                            id: index,
                            localTarget: roundWithPrecision(localTarget, 1),
                            targetPercent,
                            scalePercent,
                            localPercent: roundWithPrecision(localPercent, 1),
                            name,
                            val: currentValue,
                            color,
                            countNumber,
                            section
                        }]
                    }, [])
                } else if (val === 'listCorporateLanguageTerms') {
                    // if it's a number - it's  Terms
                    const termsSettings = currentBenchmarkSettings?.settings['listCorporateLanguageTerms']?.values['_list'] || {}
                    const { valuesClix, dataCorporateLanguageClix: ids, dataTermsClix } = checkClixNotZero(currentBenchmarkSettings?.settings)
                    // if it's a number - it's  Terms
                    corporateList = ids?.length && ids.reduce((acc, currentValue, index) => {
                        countNumber = resultList[`categorysizesum.dataCorporateLanguage.${currentValue}`]
                        const localSettings = termsSettings[currentValue]
                        localTarget = getValue(localSettings, "target", 50)
                        const localScaleFrom = getValue(localSettings, "scaleFrom", 0)
                        const localScaleTo = getValue(localSettings, "scaleTo", 100)
                        const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50)
                        const localTargetYellowRed = getValue(localSettings, "yellowRed", 50)
                        localPercent = countNumber / (countWords / 100)
                        section = "CorporateLanguage"
                        categoryNames.map(el => {
                            if (String(el.id) === String(currentValue)) {
                                name = el.name[convertLanguagesToShort(i18nLanguage)]
                                // Add avaible name in the config to display later:
                                config.clixDisplay.map(element => {
                                    if (element.title === "CorporateLanguage") {
                                        element.subCat.push(name)
                                    }
                                })
                            }
                        })
                        // convert real % into  scale size percent
                        const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo)
                        targetPercent = targetPercentTmp
                        scalePercent = scalePercentTmp
                        color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed)
                        return [...acc, {
                            id: index,
                            localTarget: roundWithPrecision(localTarget, 1),
                            targetPercent,
                            scalePercent,
                            localPercent: roundWithPrecision(localPercent, 1),
                            name,
                            val: currentValue,
                            color,
                            countNumber,
                            section
                        }]
                    }, [])
                }
                return [...acc, {
                    id: index,
                    localTarget: roundWithPrecision(localTarget, 1),
                    targetPercent,
                    scalePercent,
                    localPercent: roundWithPrecision(localPercent, 1),
                    name,
                    val,
                    color,
                    countNumber,
                    section
                }]
            }
            return acc
        }, [])
        //  remove corporate list duplicate and take only object:
        const uniqueCorporateList = (!!corporateList?.length && Array.isArray(corporateList)) && corporateList.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.name === current.name)) {
                accumulator.push(current)
            }
            accumulator = accumulator.filter(el => typeof el === "object")
            return accumulator
        }, [])
        //  remove term list duplicate and take only object:
        const uniqueTermList = (!!termList?.length && Array.isArray(termList)) && termList.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.name === current.name)) {
                accumulator.push(current)
            }
            accumulator = accumulator.filter(el => typeof el === "object")
            return accumulator
        }, [])
        if (uniqueCorporateList?.length && Array.isArray(uniqueCorporateList)) {
            clixListIs.push(...uniqueCorporateList)
        }
        if (uniqueTermList?.length && Array.isArray(uniqueTermList)) {
            clixListIs.push(...uniqueTermList)
        }
        //  // To get the available heading:
        config.clixDisplay.map((el, i) => {
            clixListIs?.length && clixListIs.map(list => {
                if (el.subCat.some(element => t(element) === list.name) && typeof list.section === "string" && list.section.length === 0) {
                    heading.push({ "heading": el.title, "item": list.name })
                } else if (typeof list.section === "string" && list.section.length !== 0) {
                    heading.push({ "heading": list.section, "item": list.name })
                }
            })
        })
        return clixListIs
    }

    // Clix Result 1:
    if (!isObjectEmpty(currentBenchmarkSettings1)) {
        clixList1 = clixDisplay(currentBenchmarkSettings1, resultList1, categoryNames1, heading1)
    }


    // ********************************************************************
    // ********************************************************************
    // *************************** HIX details: ***************************
    // ********************************************************************
    // ********************************************************************

    /*** array2maxlist.moreWordsInLetters ***/
    let hixTableCells1 = []
    if (!isObjectEmpty(resultList1[`array2maxlist.moreWordsInLetters`])) {
        hixTableCells1 = Object.entries(resultList1[`array2maxlist.moreWordsInLetters`]).map(([word, freq]) => Object({ word, freq, length: word.length }))
            .sort((a, b) => b.word.length - a.word.length)
    }
    let hixTableCells2 = []
    if (!isObjectEmpty(resultList2[`array2maxlist.moreWordsInLetters`])) {
        hixTableCells2 = Object.entries(resultList2[`array2maxlist.moreWordsInLetters`]).map(([word, freq]) => Object({ word, freq, length: word.length }))
            .sort((a, b) => b.word.length - a.word.length)
    }

    const sets = [{}, {}]
    if (!isObjectEmpty(resultList1)) {
        sets[0].benchmarkWarning = (Object.keys(resultList1["benchmarkId"]).length > 1)
        sets[0].counter = setResult(resultList1)
    }
    if (!isObjectEmpty(resultList2)) {
        sets[1].benchmarkWarning = (Object.keys(resultList2["benchmarkId"]).length > 1)
        sets[1].counter = setResult(resultList2)
    }

    if (isObjectEmpty(sets[0]) && isObjectEmpty(sets[1]))
        return <Redirect to={"/"} />

    return (
        <MainPageWrapper>
            <div className={style.mainCont}>
                <NavLink to="/dashboard/compare">
                    <Button variant="contained" color="primary" className={`${style.breadcrumbs}`}>
                        <ArrowLeftIcon />
                        {t('dashFiltersboard')}
                    </Button>
                </NavLink>
                <div className={`${style.variants}`}>
                    <div className={`${style.variantsCont}`}>
                        <Button variant="contained" color="default" className={`${style.breadcrumbs}`} onClick={downloadPDf}>
                            {t('download-pdf')}
                        </Button>
                    </div>
                </div>
                <br />
                <br />
                <div className={style.mainCont} ref={pdfRef}>
                    {/* First PAGE: */}
                    <div style={{ padding: "50px" }} ref={pdfRef1}>
                        <div className={style.AverageBar} style={{ backgroundColor: bgColors.bgPrimary0, padding: "50px", }}>
                            <span style={{ float: "right" }}>
                                <img src={logo} alt="logo" className={style.img} />
                            </span>
                            <span className={style.info}>
                                {t("pdfreport_date")}: {date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()}
                            </span>
                            <span className={style.info}>
                                {t("pdfreport_analyse")}: {!!archiveResult.name ? archiveResult.name : t("noNameForAnalysis_dashboard_pdfHeader")}
                            </span>
                            <span className={style.info}>
                                {t("pdfreport_text_type")}: {benchmarkName.current}
                            </span>
                            <span className={style.info}>
                                {t("pdfreport_author")}: {userName}
                            </span>
                            <br />
                            {(resultList1["mean.formulaCLIX"] !== undefined ||
                                resultList1["mean.formulaHix"] !== undefined ||
                                resultList1["mean.formulaFlesch"] !== undefined
                            ) &&
                                <div className={style.title}>
                                    <span className={style.titleLineStart}>
                                    </span>
                                    <span>
                                        &nbsp;   {t("pdfreport_title_index")}
                                    </span>
                                    <span className={style.titleLine}>
                                    </span>
                                </div>}
                            <br />
                            {resultList1["mean.formulaHix"] !== undefined ?
                                <RadialBar
                                    // value={3.3}
                                    value={roundWithPrecision(resultList1["mean.formulaHix"], 1)}
                                    max={20}
                                    color={bgColors.bgPrimary100}
                                    text={t("dash_hix_text")}
                                    bg={"#dd0d08"}
                                    name={t('HIX_dashboard_chartID')}
                                    // targetVal={22}
                                    targetVal={targetHix1}
                                    mainText={t("dash_hix_main_text")}
                                    // text={"Der Hohenheimer Index (HIX) misst die Verständlichkeit Ihres Textes von 0 (sehr schwer verständlich) bis 10 (sehrleicht zu verstehen)."}
                                    fullName={t("HOHENHEIMER INDEX")}
                                    combine={true}
                                />
                                :
                                resultList1["mean.formulaFlesch"] !== undefined && <RadialBar
                                    value={roundWithPrecision(resultList1["mean.formulaFlesch"], 1)}
                                    max={100}
                                    color={bgColors.bgPrimary100}
                                    text={t("dash_flesch_text")}
                                    bg={"#dd0d08"}
                                    withNavLink={false}
                                    combine={true}
                                    name={t("FLESCH_dashboard_chartID")}
                                    targetVal={targetFlesch}
                                    mainText={t("dash_flesch_main_text")} />}
                            <br />
                            {resultList1["mean.formulaCLIX"] !== undefined &&
                                <RadialBar
                                    color={bgColors.bgPrimary100}
                                    // value={4}
                                    value={roundWithPrecision(resultList1["mean.formulaCLIX"], 1)}
                                    // text={"dash_clix_text"}
                                    bg={"#dd0d08"}
                                    name={t('CLIX_dashboard_chartID')}
                                    max={100}
                                    // targetVal={23}
                                    targetVal={targetCLIX1}
                                    mainText={t("dash_clix_main_text")}
                                    text={"Der Marken-Index (MIX) misst Unam incolunt Belgae, aliam Aquitani, tertiam 0 (nicht Markenkonform) bis 100 (absolut Markenkonform)."}
                                    fullName={t("CORPORATE LANGUAGE INDEX")}
                                    combine={true}
                                />
                            }
                            <br />
                            {(!isObjectEmpty(archiveResult) || (tonalityRows?.length && !archiveResult.dataColorWords)) && <>
                                <div className={style.title}>
                                    <span className={style.titleLineStart}>
                                    </span>
                                    <span>
                                        &nbsp; {t("pdfreport_title_tonality")}
                                    </span>
                                    <span className={style.titleLine}>
                                    </span>
                                </div>
                                <Tonality center="0" wide={!archiveResult.dataColorWords}
                                    type={"single"}
                                /></>}
                        </div>
                    </div>

                    {/* Second PAGE: */}
                    {<div style={{ padding: "50px" }} ref={pdfRef2}>
                        <div className={style.AverageBar} style={{ backgroundColor: bgColors.bgPrimary0, padding: "50px", }}>
                            <div style={{ marginTop: "-30px" }}>

                                {<div>
                                    <div className={style.title}>
                                        <span className={style.titleLineStart}>
                                        </span>
                                        <span>
                                            &nbsp; {t("pdfreport_title_terminology")}
                                        </span>
                                        <span className={style.titleLine}>
                                        </span>
                                    </div>
                                    {(!isObjectEmpty(clixList1) && resultList1["mean.formulaCLIX"] !== undefined) ?
                                        <div style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: "10px", margin: "30px 0 0 0" }} className={style.cells}>
                                            <div className={`mb-5`}>
                                                <VColoredLinearChart
                                                    cells={clixList1} heading={heading1}
                                                />
                                            </div>
                                        </div>
                                        : <div />}
                                </div>}
                            </div>
                        </div>
                    </div>}

                    {/* Third PAGE: */}
                    {<div style={{ padding: "50px" }} ref={pdfRef3}>
                        <div className={style.AverageBar} style={{ backgroundColor: bgColors.bgPrimary0, padding: "50px", }}>
                            <>
                                <div className={style.title}>
                                    <span className={style.titleLineStart}>
                                    </span>
                                    <span>
                                        &nbsp; {t("pdfreport_title_grammar")}
                                    </span>
                                    <span className={style.titleLine}>
                                    </span>
                                </div>
                                {resultList1["mean.formulaHix"] !== undefined && <div style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: "10px", margin: "30px 0 0 0" }} className={style.cells}>
                                    <div className={`mb-5`}>
                                        {
                                            sets[0].counter.map((value, index) =>
                                                <div key={index} className={`d-flex flex-lg-nowrap flex-md-wrap`} style={{ gridGap: "10px" }}>
                                                    <div className={`w-100`} style={index > 0 ? { borderTop: `4px solid #e2e1e1` } : {}}>
                                                        <PreLoaderCont
                                                            id={'diagram-' + index}
                                                            withLinear={value.withLinear}
                                                            value={value.value}
                                                            label={value.label}
                                                            color={value.color}
                                                            textsComp={value.textsComp}
                                                            text={value.text}
                                                            title={value.title}
                                                            subTitle={value.subTitle}
                                                            lCount={value.lCount}
                                                            lCountMax={value.lCountMax}
                                                            lText={value.lText}
                                                            lTitle={value.lTitle}
                                                            combine={true}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>}
                            </>
                        </div>
                    </div>}

                    {/* Fourth page: */}
                    <div style={{ padding: "50px" }} ref={pdfRef4}>
                        <div className={style.AverageBar} style={{ backgroundColor: bgColors.bgPrimary0, padding: "50px", }}>
                            {!isObjectEmpty(archiveResult) && <> <div className={style.title}>
                                <span className={style.titleLineStart}>
                                </span>
                                <span>
                                    &nbsp; {t("pdfreport_title_word")}
                                </span>
                                <span className={style.titleLine}>
                                </span>
                            </div>
                                <div style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: "10px", margin: "30px 0 0 0" }} className={style.cells}>
                                    <div className={`mb-5`}>
                                        <CompareVocabularyComponent />
                                    </div>
                                </div>
                            </>}
                            <br />
                            <br />
                            {!isObjectEmpty(archiveResult) && <div style={{ marginTop: "-30px" }}>
                                <div className={style.title}>
                                    <span className={style.titleLineStart}>
                                    </span>
                                    <span>
                                        &nbsp;  {t("pdfreport_title_word_distribution")}
                                    </span>
                                    <span className={style.titleLine}>
                                    </span>
                                </div>
                                <div style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: "10px", margin: "30px 0 0 0" }} className={style.cells}>
                                    <div className={`mb-5`}>
                                        <CompareSingleDistribution combine={true} />
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>

                </div>
            </div>
        </MainPageWrapper>
    )
}
